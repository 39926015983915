import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Stack, Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfileDetails,
  handleAppSelectedTopic,
  handleAppSelectedTopicToSaveMsg,
  handleScrollToBottom,
  handleShowAIScreen,
  handleStarMsgScreen,
} from "../../../appStore/actions/AppState/action";
import {
  fetchOldMessageFromApi,
  handleDeleteGroupMessage,
  handleFetchMessages,
  handleFetchOldMessage,
  handleGetStarMessages,
  handleStarMessage,
} from "../../../appStore/actions/ArkchatGroup/action";
import { groupMsgDeleteType } from "../../../utils/constant";
import closeIcon from "../../../assets/images/chats/closeLogo.svg";
import StarMessageView from "./StarMessageView";
import GroupMessageView from "./GroupMessageView";
import TempMessageView from "./TempMessageView";
import {
  ActionButton,
  CustomIcon,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";
import aIIcon from "../../../assets/images/chats/aIIcon.svg";
import vectorIcon from "../../../assets/images/chats/VectorIcon.svg";
import DirectMessageView from "./DirectMessageView";
import { findUser, setProfileData } from "../../../utils/helper";
import ProfileDetailModal from "../../../common/ProfileDetailModal";
import EmptyMsg from "./EmptyMsg";
import { useTranslation } from "react-i18next";

export const classes = {
  chatUserprofile: {
    bgcolor: "#E3E1E1",
    width: "30px",
    height: "30px",
    display: "flex",
    display: "-webkit-box",
    display: "-ms-flexbox",
    display: "-webkit-flex,",
    alignContent: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    WebkitBoxPack: "center",
    msFlexPack: "center",
    borderRadius: "0.15rem",
    fontWeight: "500",
    objectFit: "cover",
    img: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      borderRadius: "3px",
    },
  },
  highlight: {
    bgcolor: "blue",
  },
  chatUserprofile2: {
    bgcolor: "#E3E1E1",
    width: "25px",
    height: "25px",
    display: "flex",
    display: "-webkit-box",
    display: "-ms-flexbox",
    display: "-webkit-flex,",
    flex: "none",

    alignContent: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    WebkitBoxPack: "center",
    msFlexPack: "center",
    borderRadius: "0.15rem",
    fontWeight: "500",
    img: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      borderRadius: "3px",
    },
  },
  userlink: {
    color: "#2C86FF",
    lineHeight: "15px",
    fontSize: "13px",
    fontWeight: "450",
    cursor: "pointer",
  },
  duetime: {
    color: "#000",
    opacity: "80%",
    fontSize: "13px",
    paddingTop: "1px",
  },
  userlink2: {
    color: "#2C86FF",
    display: "inline-block",
    fontSize: "0.75rem",
  },
  userInfo: {
    marginTop: "-5px !important",
    width: "100%",
  },
  tick: {
    objectFit: "cover",
    maxWidth: "17px",
    maxHeight: "6px",
    img: {
      width: "100%",
    },
  },
  pendingT: {
    borderRadius: "4px",
    padding: "5px 10px",
    textAlign: "center",
    maxHeight: "1.5rem",
    justifyContent: "center",
  },

  txt: {
    fontSize: "11px !important;",
    marginLeft: "2px !important;",
  },
  chatRep: {
    background: "#E3E1E1",
    padding: "4px 10px",
    borderRadius: "3px",
    margin: "10px 0 8px",
    // cursor:"pointer"
  },
};

const MessageList = ({
  messageList,
  setMediaFilesOpen,
  setReplyParentMsg,
  handlegetThreadMessages,
  handleBacktoMessage,
  setMoveTopicTriggered,
  setMoveTopicMsgId,
  setisTopicOpen,
  handleReadStatusDrawer,
  handleSendTempMsg,
  handleClickTaskUpdate,
  progress,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const userData = useSelector((state) => state?.profile);
  const tempMsgList = useSelector(
    (state) => state?.arkchatgroup?.tempMessageList
  );
  const isThreadScreen = useSelector(
    (state) => state?.localAppReducer?.isThreadScreen
  );
  const selectedTopic = useSelector(
    (state) => state?.appStateData?.selectedTopicData
  );
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const showStarMessage = useSelector(
    (state) => state?.appStateData?.showStarMessage
  );
  const isScrollToBottom = useSelector(
    (state) => state?.appStateData?.isScrollToBottom
  );
  const isThreadScrollToBottom = useSelector(
    (state) => state?.appStateData?.isThreadScrollToBottom
  );
  const groupmemberdetails = useSelector(
    (state) => state?.arkchatgroup?.groupmember
  );
  const groupRecipientDetails = useSelector(
    (state) => state?.arkchatgroup?.groupRecipient
  );
  const starMsgData = useSelector((state) => state?.arkchatgroup?.starMsgData);

  const chatContainerRef = useRef(null);
  const threadContainerRef = useRef(null);
  const menuRef = useRef(null);
  const threeDotRef = useRef(null);
  const [isMoreMenuOpen, setMoreMenu] = useState(false);
  const [isDMMoreMenuOpen, setDMMoreMenuOpen] = useState(null);
  const [isDeleteGroupMsgOpen, setDeleteGroupMsgOpen] = useState(false);
  const [profileViewData, setProfileViewData] = useState("");
  const [isProfileViewOpen, setProfileViewModal] = useState(false);
  const [isDeleteGroupMsgOpenIndicatorId, setDeleteGroupMsgOpenIndicatorId] =
    useState(null);
  const [deleteGrpMsgType, setDeleteGrpMsgType] = useState("");
  const dispatch = useDispatch();
  const [isScrollingToTop, setIsScrollingToTop] = useState(false);
  const [localStarMsgData, setLocalStarMsgData] = useState([]);
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);
  const [startTaskOpen, setStartTaskOpen] = useState(null);

  const handleDelete = () => {
    dispatch(handleAppSelectedTopic(null));
    dispatch(handleAppSelectedTopicToSaveMsg(null));
    dispatch(handleFetchMessages(selectedGroup?.pnId, null));
  };

  ////////
  const scrollToBottom = () => {
    if (chatContainerRef && chatContainerRef.current && !isThreadScreen) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    } else if (
      threadContainerRef &&
      threadContainerRef?.current &&
      isThreadScreen &&
      isThreadScrollToBottom
    ) {
      threadContainerRef.current.scrollTop =
        threadContainerRef.current.scrollHeight;
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMoreMenu(null);
    }
  };
  const toggleItemMenu = (item) => {
    if (isMoreMenuOpen?.timetoken === item?.timetoken) {
      setMoreMenu(null);
    } else {
      setMoreMenu(item);
    }
  };

  const handleStartTask = (item) => {
    if (startTaskOpen?.timetoken === item?.timetoken) {
      setStartTaskOpen(null);
    } else {
      setStartTaskOpen(item);
    }
  };

  const handleDeleteGrpMsg = (msgData) => {
    if (deleteGrpMsgType === groupMsgDeleteType?.me) {
      dispatch(handleDeleteGroupMessage(msgData, false)).then((res) => {});
      setDeleteGrpMsgType("");
      setDeleteGroupMsgOpen(false);
      setDeleteGroupMsgOpenIndicatorId(null);
    } else if (deleteGrpMsgType === groupMsgDeleteType?.all) {
      dispatch(handleDeleteGroupMessage(msgData, true)).then((res) => {});
      setDeleteGrpMsgType("");
      setDeleteGroupMsgOpen(false);
      setDeleteGroupMsgOpenIndicatorId(null);
    }
  };
  const handleStarUnstarMsg = (timetoken, isalreadyStared) => {
    const reqBoby = {
      pn_id: selectedGroup?.pnId,
      timetoken: timetoken,
    };
    dispatch(
      handleStarMessage(
        reqBoby,
        selectedGroup?.pnId,
        selectedTopic,
        messageList,
        isThreadScreen
      )
    ).then((res) => {
      if (res?.status) {
        if (localStarMsgData?.includes(reqBoby?.timetoken)) {
          const updatedData = localStarMsgData?.filter(
            (item) => item !== reqBoby?.timetoken
          );
          setLocalStarMsgData(updatedData);
        } else if (
          !isalreadyStared?.includes(userData?.profileData?.data?.id)
        ) {
          setLocalStarMsgData([...localStarMsgData, reqBoby?.timetoken]);
        }

        setMoreMenu(null);
        dispatch(handleGetStarMessages(selectedGroup?.pnId));
        //reqBobydispatch(handleFetchMessages({ groupId: selectedGroup?.id }));
      }
    });
  };
  const handleAiBtnCLick = () => {
    if (selectedGroup) {
      dispatch(handleShowAIScreen(true));
    }
  };
  const handleRemoveStarScreen = () => {
    dispatch(handleStarMsgScreen(!showStarMessage));
    dispatch(handleFetchMessages(selectedGroup?.pnId, selectedTopic?.id));
    dispatch(handleScrollToBottom(true));
  };

  const scrollToRepliedMessage = (messageId) => {
    setHighlightedMessageId(messageId);
    setTimeout(() => {
      setHighlightedMessageId(null);
    }, 2000);

    const repliedMessageElement = document.getElementById(
      `replied-message-${messageId}`
    );
    if (repliedMessageElement) {
      repliedMessageElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  ////
  // Debounce function to improve performance
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleScroll = debounce(() => {
    const { scrollTop } = chatContainerRef.current;
    const activeChannel = selectedGroup?.pnId;

    let shouldConsiderScrollTop = false;
    if (messageList?.length > 0) {
      shouldConsiderScrollTop = messageList[0]?.pnId === activeChannel;
    }

    const isNearTop =
      scrollTop <= 201 && shouldConsiderScrollTop && messageList?.length > 20;

    // Only update state if necessary
    if (isScrollingToTop !== isNearTop) {
      setIsScrollingToTop(isNearTop);
    }
  }, 100); // Debounce for 100ms

  const handleShowUserProfile = (userToFindData) => {
    let data = findUser(groupmemberdetails, userToFindData);

    if (!data) {
      setProfileViewModal(true);
      dispatch(getUserProfileDetails(userToFindData)).then((res) => {
        data = res;
        setProfileData(data, setProfileViewData, setProfileViewModal);
      });
    } else {
      setProfileData(data, setProfileViewData, setProfileViewModal);
    }
  };

  // const setProfileData = (data) => {
  //   const viewData = {
  //     companyName: data?.userData?.subscriberFirmName || "",
  //     userName: data?.userInfo?`${data?.userInfo?.firstName} ${data?.userInfo?.lastName}` :`${data?.firstName} ${data?.lastName}`,
  //     indusrtyName: data?.userData?.subscriberAccountIndustry || data?.activeSubscriber?.subscriberAccountIndustry,
  //     cityName: data?.userData?.city || data?.city,
  //     countryName: data?.userData?.country || data?.country,
  //     profileBrief: data?.userInfo?.profileBrief || data?.profileBrief,
  //     profileImgUrl: data?.userInfo?.profileImgUrl || data?.profileImgUrl,
  //     coverImgUrl: data?.userInfo?.coverImgUrl || data?.coverImgUrl,
  //     isGroup: false,
  //     website: data?.userInfo?.website || data?.website,
  //     linkedin: data?.userInfo?.linkedin || data?.linkedin,
  //   };

  //   setProfileViewData(viewData);
  //   setProfileViewModal(true);
  // };

  /////

  useEffect(() => {
    if (isScrollingToTop) {
      dispatch(handleScrollToBottom(false));

      const container = chatContainerRef.current;
      if (container) {
        // Save the current scroll position relative to the bottom
        const currentScrollPosition =
          container.scrollHeight - container.scrollTop;

        if (
          (selectedGroup?.isGroup || !selectedGroup?.isGroup) &&
          !isThreadScreen &&
          !showStarMessage
        ) {
          dispatch(
            handleFetchOldMessage(
              selectedGroup?.pnId,
              selectedTopic?.id,
              messageList[0]?.timetoken
            )
          ).then((res) => {
            if (res?.length > 0) {
              // After prepending messages, adjust the scroll position
              setTimeout(() => {
                container.scrollTop =
                  container.scrollHeight - currentScrollPosition;
              }, 0); // Timeout ensures scroll adjustment after DOM update
            } else {
              const page = Math.floor(messageList?.length / 50) + 1;
              dispatch(fetchOldMessageFromApi(selectedGroup, {}, page)).then(
                () => {
                  setTimeout(() => {
                    container.scrollTop =
                      container.scrollHeight - currentScrollPosition;
                  }, 0);
                }
              );
            }
          });
        } else if (showStarMessage) {
          const page = Math.floor(starMsgData?.messages?.length / 50) + 1;
          dispatch(handleGetStarMessages(selectedGroup?.pnId, page)).then(
            () => {
              setTimeout(() => {
                container.scrollTop =
                  container.scrollHeight - currentScrollPosition;
              }, 0);
            }
          );
        }
      }
    }
  }, [isScrollingToTop]);

  /////
  useEffect(() => {
    if (isScrollToBottom || isThreadScrollToBottom) {
      scrollToBottom();
    }
  }, [messageList, tempMsgList]);

  useEffect(() => {
    if (selectedGroup?.isGroup) {
      dispatch(handleGetStarMessages(selectedGroup?.pnId));
    }
  }, [dispatch, selectedGroup]);
  return (
    <>
      <Box display={"flex"} marginTop="8px">
        <ProfileDetailModal
          setProfileViewModal={setProfileViewModal}
          isProfileViewOpen={isProfileViewOpen}
          profileViewData={profileViewData}
          setProfileViewData={setProfileViewData}
        />
        {isThreadScreen && (
          <Box mr={"0.4rem"}>
            <ActionButton
              onClick={handleBacktoMessage}
              size="small"
              variant="contained"
            >
              <CustomIcon sx={{ width: "70%" }} src={closeIcon} />
            </ActionButton>
          </Box>
        )}
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            padding: isThreadScreen ? "0px" : "2px 16px 2px 10px",
            backgroundColor: isThreadScreen ? "#183243" : "#E1E1E1",
          }}
        >
          {!isThreadScreen ? (
            <Stack
              justifyContent={"center"}
              width={"80%"}
              direction={"row"}
              alignItems={"center"}
              spacing={2}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {selectedGroup?.name}{" "}
                {selectedGroup?.isBuyerSellerGroup
                  ? `- Buyer/Seller Group`
                  : ""}
              </Typography>
              {selectedGroup?.isGroup && !showStarMessage && selectedTopic && (
                <Chip
                  sx={{ maxWidth: "50%" }}
                  label={
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: 600 }}
                      title={`${selectedTopic.name}`}
                    >{`${selectedTopic.name}`}</Typography>
                  }
                  variant="outlined"
                  onDelete={handleDelete}
                />
              )}
              {selectedGroup?.isGroup && showStarMessage && (
                <Chip
                  sx={{ maxWidth: "50%" }}
                  label={"Starred Chats"}
                  variant="outlined"
                  onDelete={handleRemoveStarScreen}
                />
              )}
            </Stack>
          ) : (
            <Stack
              sx={{
                width: "60.7%",
                justifyContent: "flex-start",
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  fontWeight={200}
                  ml={"0.4rem"}
                  fontSize={"0.8rem"}
                  color={"#fff"}
                >
                  Message Thread
                </Typography>
              </Box>
            </Stack>
          )}
          <Box display={"flex"} alignItems={"center"} gap={"0.4rem"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={0.5}
              sx={{ cursor: "pointer" }}
              onClick={() => setMediaFilesOpen(true)}
              mr={isThreadScreen ? "0.4rem" : "0rem"}
            >
              <Box>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5562 3.32087L10.543 0.470187C10.2225 0.166969 9.80297 0 9.36178 0H3.625C2.67728 0 1.90625 0.771031 1.90625 1.71875V14.2812C1.90625 15.229 2.67728 16 3.625 16H12.375C13.3227 16 14.0938 15.229 14.0938 14.2812V4.56944C14.0938 4.09916 13.8978 3.64406 13.5562 3.32087ZM12.6457 3.75H10.3125C10.2263 3.75 10.1562 3.67991 10.1562 3.59375V1.39488L12.6457 3.75ZM12.375 15.0625H3.625C3.19422 15.0625 2.84375 14.712 2.84375 14.2812V1.71875C2.84375 1.28797 3.19422 0.9375 3.625 0.9375H9.21875V3.59375C9.21875 4.19684 9.70941 4.6875 10.3125 4.6875H13.1562V14.2812C13.1562 14.712 12.8058 15.0625 12.375 15.0625Z"
                    fill={isThreadScreen ? "#fff" : "#000000"}
                  />
                </svg>
              </Box>
              <Typography
                fontSize={"0.85rem"}
                color={isThreadScreen ? "#fff" : "#000000"}
              >
                {selectedGroup?.isGroup && (
                  <>
                    {isThreadScreen
                      ? "Thread Files"
                      : selectedGroup !== null && selectedTopic !== null
                      ? "Topic Files"
                      : selectedGroup !== null && !isThreadScreen
                      ? "Group Files"
                      : "Files"}
                  </>
                )}
                {!selectedGroup?.isGroup && "Files"}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        {isThreadScreen && (
          <Box display={"flex"} gap={"0.4rem"} ml={"0.4rem"}>
            <Box>
              <ActionButton
                onClick={handleAiBtnCLick}
                size="small"
                variant="contained"
              >
                <CustomIcon src={aIIcon} />
              </ActionButton>
            </Box>
            <Box>
              <ActionButton
                sx={{ backgroundColor: showStarMessage ? "grey" : "" }}
                onClick={() =>
                  selectedGroup &&
                  dispatch(handleStarMsgScreen(!showStarMessage))
                }
                size="small"
                variant="contained"
              >
                <SmallIcon src={vectorIcon} />
              </ActionButton>
            </Box>
          </Box>
        )}
      </Box>

      <Box
        maxWidth={"100%"}
        sx={{
          position: "relative",
          height: "100%",
          overflowY: "auto",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "3px",
          },
          "@media (max-width: 1194px)": {
            height: "69%",
          },
        }}
        ref={isThreadScreen ? threadContainerRef : chatContainerRef}
        onScroll={handleScroll}
      >
        {(selectedGroup?.isGroup || !selectedGroup?.isGroup) &&
          messageList?.length === 0 &&
          tempMsgList?.length === 0 &&
          !showStarMessage && (
            <EmptyMsg
              content={
                selectedGroup?.isGroup
                  ? appContent?.emptyGroupMsgContent
                  : appContent?.emptyDmMsgContent
              }
            />
          )}

        {showStarMessage && selectedGroup?.isGroup ? (
          <Box>
            {starMsgData?.messages?.length > 0 &&
              starMsgData?.messages?.map((item) => (
                <StarMessageView
                  handleShowUserProfile={handleShowUserProfile}
                  key={item?.timetoken + "starmessageGrpList"}
                  item={item}
                  isMoreMenuOpen={isMoreMenuOpen}
                  threeDotRef={threeDotRef}
                  userData={userData}
                  isDeleteGroupMsgOpenIndicatorId={
                    isDeleteGroupMsgOpenIndicatorId
                  }
                  handleClickTaskUpdate={handleClickTaskUpdate}
                  isDeleteGroupMsgOpen={isDeleteGroupMsgOpen}
                  handleReadStatusDrawer={handleReadStatusDrawer}
                  setisTopicOpen={setisTopicOpen}
                  setMoveTopicMsgId={setMoveTopicMsgId}
                  setMoveTopicTriggered={setMoveTopicTriggered}
                  setDeleteGroupMsgOpenIndicatorId={
                    setDeleteGroupMsgOpenIndicatorId
                  }
                  setDeleteGroupMsgOpen={setDeleteGroupMsgOpen}
                  handlegetThreadMessages={handlegetThreadMessages}
                  setMoreMenu={setMoreMenu}
                  menuRef={menuRef}
                  isThreadScreen={isThreadScreen}
                  selectedGroup={selectedGroup}
                  toggleItemMenu={toggleItemMenu}
                  startTaskOpen={startTaskOpen}
                  setStartTaskOpen={setStartTaskOpen}
                  handleStartTask={handleStartTask}
                  handleStarUnstarMsg={handleStarUnstarMsg}
                  handleDeleteGrpMsg={handleDeleteGrpMsg}
                  setHighlightedMessageId={setHighlightedMessageId}
                  bgcolor={
                    highlightedMessageId === item?.temp_timetoken
                      ? "#F9F8F8"
                      : "transparent"
                  }
                  scrollToRepliedMessage={scrollToRepliedMessage}
                />
              ))}
          </Box>
        ) : (
          <>
            {messageList?.length > 0 &&
              messageList?.map((item, i) => (
                <Box key={"GroupMessageViewParent" + i}>
                  {item?.participants?.includes(
                    userData?.profileData?.data?.id
                  ) && (
                    <GroupMessageView
                      item={item}
                      groupRecipientDetails={groupRecipientDetails}
                      key={item?.temp_timetoken + "GroupmessageView" + i}
                      isMoreMenuOpen={isMoreMenuOpen}
                      handleShowUserProfile={handleShowUserProfile}
                      threeDotRef={threeDotRef}
                      userData={userData}
                      isDeleteGroupMsgOpenIndicatorId={
                        isDeleteGroupMsgOpenIndicatorId
                      }
                      handleClickTaskUpdate={handleClickTaskUpdate}
                      isDeleteGroupMsgOpen={isDeleteGroupMsgOpen}
                      handleReadStatusDrawer={handleReadStatusDrawer}
                      setisTopicOpen={setisTopicOpen}
                      setMoveTopicMsgId={setMoveTopicMsgId}
                      setMoveTopicTriggered={setMoveTopicTriggered}
                      setDeleteGroupMsgOpenIndicatorId={
                        setDeleteGroupMsgOpenIndicatorId
                      }
                      setDeleteGroupMsgOpen={setDeleteGroupMsgOpen}
                      progress={progress}
                      handlegetThreadMessages={handlegetThreadMessages}
                      setMoreMenu={setMoreMenu}
                      menuRef={menuRef}
                      isThreadScreen={isThreadScreen}
                      selectedGroup={selectedGroup}
                      toggleItemMenu={toggleItemMenu}
                      startTaskOpen={startTaskOpen}
                      setStartTaskOpen={setStartTaskOpen}
                      handleStartTask={handleStartTask}
                      handleStarUnstarMsg={handleStarUnstarMsg}
                      handleDeleteGrpMsg={handleDeleteGrpMsg}
                      setDeleteGrpMsgType={setDeleteGrpMsgType}
                      localStarMsgData={localStarMsgData}
                      bgcolor={
                        highlightedMessageId === item?.temp_timetoken
                          ? "#F9F8F8"
                          : "transparent"
                      }
                      scrollToRepliedMessage={scrollToRepliedMessage}
                    />
                  )}
                </Box>
              ))}
            {tempMsgList?.length > 0 &&
              selectedGroup?.isGroup &&
              tempMsgList?.map((item, i) => (
                <TempMessageView
                  handleShowUserProfile={handleShowUserProfile}
                  selectedGroup={selectedGroup}
                  item={item}
                  key={item?.temp_timetoken + "tempMsg"}
                  threeDotRef={threeDotRef}
                  userData={userData}
                  handleSendTempMsg={handleSendTempMsg}
                  progress={progress}
                />
              ))}
          </>
        )}

        <>{/* This is for Render DM */}</>
        {!selectedGroup?.isGroup && (
          <>
            {messageList?.length > 0 &&
              messageList?.map((item) => (
                <DirectMessageView
                  handleShowUserProfile={handleShowUserProfile}
                  isDMMoreMenuOpen={isDMMoreMenuOpen}
                  setDMMoreMenuOpen={setDMMoreMenuOpen}
                  isDeleteGroupMsgOpenIndicatorId={
                    isDeleteGroupMsgOpenIndicatorId
                  }
                  isDeleteGroupMsgOpen={isDeleteGroupMsgOpen}
                  setDeleteGrpMsgType={setDeleteGrpMsgType}
                  setDeleteGroupMsgOpen={setDeleteGroupMsgOpen}
                  setDeleteGroupMsgOpenIndicatorId={
                    setDeleteGroupMsgOpenIndicatorId
                  }
                  handleDeleteGrpMsg={handleDeleteGrpMsg}
                  item={item}
                  key={item?.temp_timetoken + "Directmessage"}
                  threeDotRef={threeDotRef}
                  userData={userData}
                  handleSendTempMsg={handleSendTempMsg}
                  setReplyParentMsg={setReplyParentMsg}
                  bgcolor={
                    highlightedMessageId === item?.temp_timetoken
                      ? "#F9F8F8"
                      : "transparent"
                  }
                  scrollToRepliedMessage={scrollToRepliedMessage}
                />
              ))}
            {tempMsgList?.length > 0 &&
              tempMsgList?.map((item, i) => (
                <TempMessageView
                  handleShowUserProfile={handleShowUserProfile}
                  selectedGroup={selectedGroup}
                  item={item}
                  key={item?.temp_timetoken + "DirecttempMsg"}
                  threeDotRef={threeDotRef}
                  userData={userData}
                  handleSendTempMsg={handleSendTempMsg}
                  progress={progress}
                />
              ))}
          </>
        )}
      </Box>
    </>
  );
};

export default MessageList;
