import React, { useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Select,
  FormControl,
  MenuItem,
  Button,
  TextField,
  InputLabel,
} from "@mui/material";
import closeLogo from "../../../assets/images/chats/closeLogo.svg";

import { useTranslation } from "react-i18next";
import { CloseButton, CloseIcon } from "../../Profille/CustomDrawer";
import { useDispatch } from "react-redux";
import { handleTaskStart } from "../../../appStore/actions/ArkchatGroup/action";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const StartTask = ({
  open,
  taskData,
  setStartTaskOpen,
  isActualTime = false,
  setActualTimeValue,
  actualTimeValue,
  handleActualTime,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });

  const [timeType, setTimeType] = useState(
    taskData?.estimated_time?.timeType || "hours"
  );

  const [timeValue, setTimeValue] = useState("");

  const dispatch = useDispatch();

  // Prevent closing modal on clicks inside the Box
  const handleBoxClick = (event) => {
    event.stopPropagation();
  };
  const handleClose = () => {
    setStartTaskOpen(null);
    setTimeType("");
    setTimeValue("");

    isActualTime && setActualTimeValue("");
  };

  const handleClickStart = () => {
    const reqBody = {
      estimated_time: {
        timeType: timeType,
        timeValue: +timeValue,
      },
      start_time: Math.floor(Date.now() / 10000),
      task_status: 6,
    };
    dispatch(handleTaskStart(taskData?.temp_timetoken, reqBody));
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} onClick={handleBoxClick}>
          <Box display={"flex"} alignItems={"center"} gap={"0.4rem"}>
            <Box>
              <CloseButton
                onClick={handleClose}
                size="small"
                variant="contained"
              >
                <CloseIcon src={closeLogo} alt="close" />
              </CloseButton>
            </Box>
            <Box>
              <Typography fontSize={"1rem"} variant="h6" fontWeight={600}>
                {!isActualTime ? appContent?.startTaskHeading : "Actual Time"}
              </Typography>
            </Box>
          </Box>
          {!isActualTime && (
            <Box width={"80%"} m={"auto"}>
              <Typography fontSize={"0.75rem"} variant="caption">
                {appContent?.startTaskSubHeading}
              </Typography>
            </Box>
          )}
          <Box width={"90%"} mx={"auto"} mt={"1.5rem"}>
            <FormControl variant="standard" fullWidth>
              <InputLabel>Select</InputLabel>
              <Select
                required
                onChange={(e) => setTimeType(e.target.value)}
                label="Select"
                value={timeType}
                disabled={isActualTime}
              >
                {/* <MenuItem value={"days"}>Days</MenuItem> */}
                <MenuItem value={"hours"}>Hours</MenuItem>
                <MenuItem value={"minutes"}>Minutes</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ width: "100%", mt: "1rem" }}>
              <TextField
                sx={{ width: "100%" }}
                type="text"
                label={
                  timeType === "hours"
                    ? "Numbers of hours"
                    : "Numbers of minutes"
                }
                variant="standard"
                placeholder={
                  timeType === "hours"
                    ? "Numbers of hours"
                    : "Numbers of minutes"
                }
                name="timeValue"
                value={!isActualTime ? timeValue : actualTimeValue}
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    !isActualTime
                      ? setTimeValue(e.target.value)
                      : setActualTimeValue(e.target.value);
                  }
                }}
                required
              />
            </FormControl>
          </Box>
          <Box mt={"1rem"} textAlign={"center"}>
            <Button
              sx={{
                color: "white",
                padding: "0.2rem 1rem",
                borderRadius: "0.2rem",
                bgcolor: "#DB0011",
                boxShadow: "none",
                textTransform: "none",
                ":hover": {
                  color: "white",
                  padding: "0.2rem 1rem",
                  borderRadius: "0.2rem",
                  bgcolor: "#DB0011",
                },
              }}
              disabled={
                !isActualTime ? !timeValue || !timeType : !actualTimeValue
              }
              variant="contained"
              onClick={!isActualTime ? handleClickStart : handleActualTime}
            >
              {!isActualTime ? "Start" : "Completed"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default StartTask;
