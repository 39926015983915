import React from "react";
import { Box, Typography, Stack, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import arrowForwardIosOutlined from "../../../assets/images/chats/down_arrow.svg";
import {
  AccountLevelArkchatPermission,
  accountType,
} from "../../../utils/constant";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const MenuDrawer = ({
  setMenuDrawerOpen,
  handleClickLogout,
  setLanguageMenuOpen,
  setSettingDrawerOpen,
  setMyProfileDrawerOpen,
  setFirmProfileDrawerOpen,
  setPricingDrawerOpen,
  setSupportDrawerOpen,
  showPricing = false,
}) => {
  const currentuserdetails = useSelector(
    (state) => state?.profile?.profileData
  );
  const navigate = useNavigate();
  const handleMenuLogoutClick = () => {
    setMenuDrawerOpen(false);
    handleClickLogout();
  };
  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setMenuDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>Menu</Typography>
      </Stack>

      <>
        <Box width={"85%"} margin={"auto"}>
          <Box
            overflow={"auto"}
            maxHeight={"480px"}
            width={"95%"}
            sx={{
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
            }}
          >
            <Box
              bgcolor={"#E1E1E1"}
              sx={{
                cursor: "pointer",
                borderRadius: "2px",
              }}
              mb={"0.9rem"}
              p={"0.5rem 1rem"}
              position={"relative"}
              onClick={() => {
                setMyProfileDrawerOpen(true);
              }}
            >
              <Typography color={"black"} fontSize={"0.87rem"} fontWeight={400}>
                My Profile
              </Typography>
              <Box position={"absolute"} right={"0.4rem"} top={"20%"}>
                <img src={arrowForwardIosOutlined} alt="logo" />
              </Box>
            </Box>

            {currentuserdetails?.data?.activeSubscriber?.subscriberPriority ===
              accountType?.Organisation &&
              currentuserdetails?.data?.activePermission !==
                AccountLevelArkchatPermission?.USER && (
                <Box
                  bgcolor={"#E1E1E1"}
                  sx={{
                    cursor: "pointer",
                    borderRadius: "2px",
                  }}
                  mb={"0.9rem"}
                  p={"0.5rem 1rem"}
                  position={"relative"}
                  onClick={() => {
                    setFirmProfileDrawerOpen(true);
                  }}
                >
                  <Typography
                    color={"black"}
                    fontSize={"0.87rem"}
                    fontWeight={400}
                  >
                    Organisation Profile
                  </Typography>
                  <Box position={"absolute"} right={"0.4rem"} top={"20%"}>
                    <img src={arrowForwardIosOutlined} alt="logo" />
                  </Box>
                </Box>
              )}

            {showPricing && (
              <Box
                bgcolor={"#E1E1E1"}
                sx={{
                  cursor: "pointer",
                  borderRadius: "2px",
                }}
                mb={"0.9rem"}
                p={"0.5rem 1rem"}
                position={"relative"}
                onClick={() => {
                  setPricingDrawerOpen(true);
                }}
              >
                <Typography
                  color={"black"}
                  fontSize={"0.87rem"}
                  fontWeight={400}
                >
                  Pricing
                </Typography>
                <Box position={"absolute"} right={"0.4rem"} top={"20%"}>
                  <img src={arrowForwardIosOutlined} alt="logo" />
                </Box>
              </Box>
            )}

            <Box
              bgcolor={"#E1E1E1"}
              sx={{
                cursor: "pointer",
                borderRadius: "2px",
              }}
              mb={"0.9rem"}
              p={"0.5rem 1rem"}
              position={"relative"}
              onClick={() => {
                setSettingDrawerOpen(true);
              }}
            >
              <Typography color={"black"} fontSize={"0.87rem"} fontWeight={400}>
                Settings
              </Typography>
              <Box position={"absolute"} right={"0.4rem"} top={"20%"}>
                <img src={arrowForwardIosOutlined} alt="logo" />
              </Box>
            </Box>

            <Link
              href={"https://www.arkchat.com/terms-of-service/"}
              underline="none"
              target="_blank"
              rel="noopener"
              color={"black"}
            >
              <Box
                bgcolor={"#E1E1E1"}
                sx={{
                  cursor: "pointer",
                  borderRadius: "2px",
                }}
                mb={"0.9rem"}
                p={"0.5rem 1rem"}
                position={"relative"}
              >
                <Typography
                  color={"black"}
                  fontSize={"0.87rem"}
                  fontWeight={400}
                >
                  Terms
                </Typography>
                <Box position={"absolute"} right={"0.4rem"} top={"20%"}>
                  <img src={arrowForwardIosOutlined} alt="logo" />
                </Box>
              </Box>
            </Link>

            <Box
              bgcolor={"#E1E1E1"}
              sx={{
                cursor: "pointer",
                borderRadius: "2px",
              }}
              mb={"0.9rem"}
              p={"0.5rem 1rem"}
              position={"relative"}
              onClick={() => {
                setLanguageMenuOpen(true);
              }}
            >
              <Typography color={"black"} fontSize={"0.87rem"} fontWeight={400}>
                Language
              </Typography>
              <Box position={"absolute"} right={"0.4rem"} top={"20%"}>
                <img src={arrowForwardIosOutlined} alt="logo" />
              </Box>
            </Box>

            <Box
              bgcolor={"#E1E1E1"}
              sx={{
                cursor: "pointer",
                borderRadius: "2px",
              }}
              onClick={() => {
                setSupportDrawerOpen(true);
              }}
              mb={"0.9rem"}
              p={"0.5rem 1rem"}
              position={"relative"}
            >
              <Typography color={"black"} fontSize={"0.87rem"} fontWeight={400}>
                Support
              </Typography>
              <Box position={"absolute"} right={"0.4rem"} top={"20%"}>
                <img src={arrowForwardIosOutlined} alt="logo" />
              </Box>
            </Box>

            <Box
              bgcolor={"#E1E1E1"}
              sx={{
                cursor: "pointer",
                borderRadius: "2px",
              }}
              mb={"0.9rem"}
              p={"0.5rem 1rem"}
              position={"relative"}
              onClick={handleMenuLogoutClick}
            >
              <Typography color={"black"} fontSize={"0.87rem"} fontWeight={400}>
                Logout
              </Typography>
              <Box position={"absolute"} right={"0.4rem"} top={"20%"}>
                <img src={arrowForwardIosOutlined} alt="logo" />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
      <Box textAlign={"center"} mt={"0.5rem"}>
        <Typography color={"#183242"} fontWeight={600} variant="subtitle2">Beta Version .</Typography>
      </Box>
    </Box>
  );
};

export default MenuDrawer;
