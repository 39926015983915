import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountLevelArkchatPermission,
  InviteType,
} from "../../../utils/constant";
import {
  billingFormData,
  getBillingAddress,
} from "../../../appStore/actions/Subscription/action";
import { groupExternalMember } from "../../../utils/externalMemberGroping";
import { useTranslation } from "react-i18next";
import no_member_icon from "../../../assets/images/chats/no_member_icon.svg";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  internalExternalButton: {
    background: "white",
    border: "1px solid #183243",
    color: "#183243",
    borderRadius: "2px",
    fontSize: "0.75rem",
    textTransform: "none",
    "&:hover": {
      background: "white",
    },
  },

  activeButton: {
    "&:hover": {
      background: "#183243",
    },
    background: "#183243",
    borderRadius: "2px",
    color: "white",
    fontSize: "0.75rem",
    textTransform: "none",
    border: "1px solid #183243",
  },
};
const ManageActiveMember = ({
  setManageActiveMemberDrawerOpen,
  setBillingFormDrawerOpen,
}) => {
  const NameBadge = createStyledIndicatorButton("#E3E1E1");
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [activeButton, setActiveButton] = useState("internal");
  const [selectedInternalMemberData, setSelectedInternalMemberData] = useState(
    []
  );
  const [selectedExternalMemberData, setSelectedExternalMemberData] = useState(
    {}
  );
  const [externalMemberData, setExternalMemberData] = useState([]);
  const selectedPlanData = useSelector(
    (state) => state?.subscription?.selectedPlanData
  );
  const billingData = useSelector((state) => state?.subscription?.billingData);
  const accountLevelPeople = useSelector(
    (state) => state?.arkchatgroup?.accountLevelPeople
  );
  const userData = useSelector((state) => state?.profile?.profileData);

  const dispatch = useDispatch();

  const handleClickMemberTypeSwitch = (type) => {
    setActiveButton(type);
  };

  const handleSelectExternalPerson = (event, selectedPerson, companyName) => {
    if (event.target.checked) {
      if (selectedExternalMemberData[companyName]?.length > 0) {
        const companyUserId = {
          [companyName]: [
            ...selectedExternalMemberData[companyName],
            selectedPerson?.userId,
          ],
        };
        setSelectedExternalMemberData({
          ...selectedExternalMemberData,
          ...companyUserId,
        });
      } else {
        const companyUserId = { [companyName]: [selectedPerson?.userId] };
        setSelectedExternalMemberData({
          ...selectedExternalMemberData,
          ...companyUserId,
        });
      }
    } else {
      const updatedCompanyUserId = selectedExternalMemberData[
        companyName
      ]?.filter((item) => item !== selectedPerson?.userId);
      const updatedData = { [companyName]: updatedCompanyUserId };
      setSelectedExternalMemberData({
        ...selectedExternalMemberData,
        ...updatedData,
      });
    }
  };

  const handleSelectCompanyAllExternalMember = (
    event,
    selectedCompanyPerson,
    companyName
  ) => {
    if (event.target.checked) {
      const userIdData = selectedCompanyPerson[companyName]?.map(
        (user) => user?.userId
      );
      const companyUserId = { [companyName]: userIdData };
      setSelectedExternalMemberData({
        ...selectedExternalMemberData,
        ...companyUserId,
      });
    } else {
      const updatedExternalGroupMember = { ...selectedExternalMemberData };
      delete updatedExternalGroupMember[companyName];
      setSelectedExternalMemberData(updatedExternalGroupMember);
    }
  };

  const handleSelectInternalMember = (event, internalUserData) => {
    if (event.target.checked || event.target.innerText === appContent?.add) {
      setSelectedInternalMemberData([
        ...selectedInternalMemberData,
        internalUserData?.userId,
      ]);
    } else {
      const updateInternUserData = selectedInternalMemberData?.filter(
        (userId) => userId !== internalUserData?.userId
      );
      setSelectedInternalMemberData(updateInternUserData);
    }
  };

  const handleClickNext = () => {
    let usersArr = [];
    usersArr = [...selectedInternalMemberData];
    for (let key in selectedExternalMemberData) {
      usersArr = [...usersArr, ...selectedExternalMemberData[key]];
    }
    const reqBody = {
      ...billingData,
      users: usersArr,
      price: selectedPlanData?.price,
      plan_id: selectedPlanData?.prodId,
      currency: selectedPlanData?.currency,
      gateway: selectedPlanData?.gateway,
    };
    dispatch(billingFormData(reqBody));

    setBillingFormDrawerOpen(true);
  };

  useEffect(() => {
    if (accountLevelPeople) {
      if (accountLevelPeople?.externalMember?.length > 0) {
        const groupedExternalData = groupExternalMember(
          accountLevelPeople?.externalMember,false,appContent?.individual
        );

        setExternalMemberData(groupedExternalData);
      }
    }
    dispatch(getBillingAddress());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountLevelPeople]);

  return (
    <Box sx={classes.drawerBody} overflow="hidden">
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setManageActiveMemberDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>{appContent?.pricing}</Typography>
      </Stack>

      <Box width={"95%"} ml={"auto"}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          mt={"0.4rem"}
          mb={"0.4rem"}
          // border={"1px solid"}
        >
          <Stack
            direction="row"
            justifyContent="left"
            alignItems="center"
            spacing={1}
          >
            <Button
              sx={
                activeButton == "internal"
                  ? classes.activeButton
                  : classes.internalExternalButton
              }
              variant="contained"
              onClick={() => handleClickMemberTypeSwitch("internal")}
            >
              {appContent?.internalContacts}
            </Button>
            <Button
              sx={
                activeButton == "external"
                  ? classes.activeButton
                  : classes.internalExternalButton
              }
              variant="contained"
              onClick={() => handleClickMemberTypeSwitch("external")}
            >
              {appContent?.externalContacts}
            </Button>
          </Stack>
        </Stack>

        <Box width={"90%"}>
          {activeButton === "internal" ? (
            <Typography fontSize={"0.85rem"}>
              {appContent?.internalContactManageContent}
            </Typography>
          ) : (
            <>
              {accountLevelPeople?.externalMember?.length > 0 && (
                <Typography fontSize={"0.85rem"}>
                  {appContent?.externalContactManageContent}
                </Typography>
              )}
            </>
          )}
        </Box>

        <Box
          width={"90%"}
          overflow={"scroll"}
          height={"calc(100vh - 16rem)"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "1px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#F9F8F8",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "grey",
            },
            "@media (max-width: 1194px)": {
              height: "calc(100vh - 20rem)",
            },
          }}
        >
          {activeButton == "internal" && (
            <Box>
              {accountLevelPeople?.internalMember?.map((item) => (
                <Stack
                  width={"100%"}
                  mb={"0.87rem"}
                  padding={"0px 4px 0px 2px"}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  key={item?.userId + "internalInvitegrpmember"}
                >
                  <Box width={"62%"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 30,
                              color: "#183243",
                            },
                          }}
                          checked={selectedInternalMemberData?.includes(
                            item?.userId
                          )}
                          onChange={(event) =>
                            handleSelectInternalMember(event, item)
                          }
                          icon={
                            <NameBadge
                              sx={{ color: "#183243" }}
                              size="small"
                              variant="contained"
                            >
                              <Typography
                                color={"#183243"}
                                fontSize={"0.75rem"}
                              >
                                {item?.userInfo?.firstName &&
                                  item?.userInfo?.firstName[0]}
                              </Typography>
                            </NameBadge>
                          }
                        />
                      }
                      label={
                        <Box>
                          <Typography
                            title={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                          >
                            {`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`
                              .length > 18
                              ? `${`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`.slice(
                                  0,
                                  18
                                )}...`
                              : `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                            <>
                              {item?.inviteType ===
                                InviteType?.internalOwnerInviteType &&
                                (item?.permission ===
                                  AccountLevelArkchatPermission?.ADMIN ||
                                  userData?.data?.accountOwner) &&
                                ", Owner"}
                              {item?.inviteType === InviteType?.internal &&
                                item?.permission ===
                                  AccountLevelArkchatPermission?.ADMIN &&
                                ", Admin"}
                            </>
                          </Typography>
                          {/* <Typography variant="caption">Admin</Typography> */}
                        </Box>
                      }
                    />
                  </Box>
                  <Box
                    width={"35%"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"0.5rem"}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      border={"1px solid none"}
                      bgcolor={item?.membershipType ? "#E1E1E1" : "#fff"}
                      color={"#183243"}
                      borderRadius={"1rem"}
                      padding={"2px 6px"}
                      maxWidth={"4rem"}
                      height={"1.4rem"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      <Typography
                        width={"4rem"}
                        fontSize={"0.7rem"}
                        title={item?.membershipType}
                        variant="caption"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.membershipType}
                      </Typography>
                    </Box>
                    <Button
                      onClick={(event) =>
                        handleSelectInternalMember(
                          {
                            target: {
                              checked: !selectedInternalMemberData?.includes(
                                item?.userId
                              ),
                              innerText: selectedInternalMemberData?.includes(
                                item?.userId
                              )
                                ? appContent?.remove
                                : appContent?.add,
                            },
                          },
                          item
                        )
                      }
                      sx={{
                        fontSize: "0.82rem",
                        padding: "0.15rem 0.5rem",
                        textTransform: "none",
                        borderColor: "#CECECE",
                        color: "#183243",
                        "&:hover": {
                          borderColor: "#CECECE",
                          color: "#183243",
                        },
                      }}
                      variant="outlined"
                    >
                      {selectedInternalMemberData?.includes(item?.userId)
                        ? appContent?.remove
                        : appContent?.add}
                    </Button>
                  </Box>
                </Stack>
              ))}
            </Box>
          )}

          {activeButton == "external" && (
            <Box>
              {externalMemberData.map((company, index) => (
                <Box
                  key={index + "externalmemberManagePricing"}
                  mb={"0.87rem"}
                  padding={"0px 4px 0px 2px"}
                >
                  <FormControlLabel
                    sx={{
                      ".MuiFormControlLabel-label": {
                        fontWeight: 500,
                        color: "#000000",
                      },
                    }}
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 30,
                            color: "#183243",
                          },
                        }}
                        checked={
                          Object.values(company)[0]?.length ===
                          selectedExternalMemberData[Object.keys(company)[0]]
                            ?.length
                        }
                        onChange={(event) =>
                          handleSelectCompanyAllExternalMember(
                            event,
                            company,
                            Object.keys(company)[0]
                          )
                        }
                        icon={
                          <NameBadge
                            sx={{ color: "#183243" }}
                            size="small"
                            variant="contained"
                          >
                            <Typography
                              color={"#183243"}
                              fontSize={"0.75rem"}
                              fontWeight={600}
                            >
                              {Object.keys(company)[0] &&
                                Object.keys(company)[0][0]}
                            </Typography>
                          </NameBadge>
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        fontSize={"1rem"}
                        fontWeight={600}
                      >
                        {Object.keys(company)[0]}
                      </Typography>
                    }
                  />
                  {Object.values(company)[0]?.map((item, i) => (
                    <Stack
                      mb={"0.4rem"}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      key={item?.userId + "externalmanagePricingpage"}
                    >
                      <Box width={"62%"} padding={"0rem 0rem 0rem 1.2rem"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 25,
                                  color: "#183243",
                                },
                              }}
                              checked={
                                selectedExternalMemberData[
                                  Object.keys(company)[0]
                                ]?.length > 0 &&
                                selectedExternalMemberData[
                                  Object.keys(company)[0]
                                ]?.includes(item?.userId)
                              }
                              onChange={(event) =>
                                handleSelectExternalPerson(
                                  event,
                                  item,
                                  Object.keys(company)[0]
                                )
                              }
                              icon={
                                <NameBadge
                                  sx={{
                                    color: "#183243",
                                    width: "1.55rem",
                                    height: "1.55rem",
                                  }}
                                  size="small"
                                  variant="contained"
                                >
                                  <Typography
                                    color={"#183243"}
                                    fontSize={"0.75rem"}
                                  >
                                    {item?.userInfo?.firstName &&
                                      item?.userInfo?.firstName[0]}
                                  </Typography>
                                </NameBadge>
                              }
                            />
                          }
                          label={
                            <Box>
                              <Typography
                                title={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                              >
                                {`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`
                                  .length > 18
                                  ? `${`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`.slice(
                                      0,
                                      18
                                    )}...`
                                  : `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                              </Typography>
                              {/* <Typography variant="caption">Admin</Typography> */}
                            </Box>
                          }
                        />
                      </Box>

                      <Box
                        width={"35%"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"0.5rem"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          border={"1px solid none"}
                          bgcolor={"#E1E1E1"}
                          color={"#183243"}
                          borderRadius={"1rem"}
                          padding={"2px 6px"}
                          maxWidth={"4rem"}
                          height={"1.4rem"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          <Typography
                            fontSize={"0.7rem"}
                            title={appContent?.monthly}
                            variant="caption"
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {appContent?.monthly}
                          </Typography>
                        </Box>
                        <Button
                          onClick={(event) =>
                            handleSelectExternalPerson(
                              {
                                target: {
                                  checked:
                                    !selectedInternalMemberData?.includes(
                                      item?.userId
                                    ),
                                },
                              },
                              item,
                              Object.keys(company)[0]
                            )
                          }
                          sx={{
                            fontSize: "0.82rem",
                            padding: "0.15rem 0.5rem",
                            textTransform: "none",
                            borderColor: "#CECECE",
                            color: "#183243",
                            "&:hover": {
                              borderColor: "#CECECE",
                              color: "#183243",
                            },
                          }}
                          variant="outlined"
                        >
                          {selectedExternalMemberData[Object.keys(company)[0]]
                            ?.length > 0 &&
                          selectedExternalMemberData[
                            Object.keys(company)[0]
                          ]?.includes(item?.userId)
                            ? appContent?.remove
                            : appContent?.add}
                        </Button>
                      </Box>
                    </Stack>
                  ))}
                </Box>
              ))}

              {accountLevelPeople?.externalMember?.length === 0 &&
                activeButton == "external" && (
                  <Stack mt={"4rem"} alignItems="center">
                    <img src={no_member_icon} alt="no_member" />
                    <Typography
                      fontSize={14}
                      width={"20rem"}
                      marginTop={"0.93rem"}
                    >
                      You don’t have any External team members. Invite your
                      vendors, partners and customers to collaborate with you.
                    </Typography>
                  </Stack>
                )}
            </Box>
          )}
        </Box>

        <Box
          position={"absolute"}
          bottom={"1rem"}
          width={"100%"}
          margin={"auto"}
          textAlign={"center"}
          mt={"2rem"}
        >
          <Button
            onClick={handleClickNext}
            sx={{
              backgroundColor: "#DB0011",
              color: "#fff",
              padding: "0.4rem 2.4rem",
              textTransform: "none",
              borderRadius: "0.15rem",
              ":hover": {
                backgroundColor: "#DB0011",
                color: "#fff",
              },
            }}
            variant="contained"
          >
            Done
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ManageActiveMember;
