import React from "react";
import ChatSend from "../../../common/SvgImageView/Send";
import { Box, TextareaAutosize } from "@mui/material";
import {
  StyledBorderDiv,
  StyledFlex,
  StyledSpaceBetween,
} from "../../../Styled";

const DrawerInput = ({
  taskData,
  checkedType,
  handleUpdateTaskApproval,
  setUpdateTaskApprovalMsg,
  updateTaskApprovalMsg,
  selectedRecipients,
  actualTimeValue
}) => {
  const triggerHandelSend = () => {
    if (updateTaskApprovalMsg) {
      handleUpdateTaskApproval(taskData);
    }
  };
  return (
    <StyledBorderDiv
      style={{
        position: "relative",
        zIndex: 1201,
        backgroundColor: "#ffff",
      }}
    >
      <TextareaAutosize
        style={{
          width: "100%",
          resize: "vertical",
          border: "none",
          outline: "none",
          lineHeight: "1.25rem",
          padding: "5px 8px",
          fontFamily: "Poppins",
          fontSize: "0.87rem",
          maxHeight: "62vh",
          minHeight: "1.1rem",
          overflow: "auto",
          marginBottom: "3rem",
        }}
        minRows={"1"}
        placeholder="Type message ..."
        //   disabled={!selectedGroup}
        //   value={newMessages}
        onChange={(e) => setUpdateTaskApprovalMsg(e.target.value)}
      />

      <StyledSpaceBetween
        style={{
          padding: "0rem .5rem",
          position: "absolute",
          bottom: "0rem",
          width: "100%",
        }}
      >
        <input type="file" multiple name="file" style={{ display: "none" }} />
        <StyledFlex></StyledFlex>

        <StyledFlex>
          <Box onClick={triggerHandelSend} className="chat-input-left-icon">
            <ChatSend
              color={
                (updateTaskApprovalMsg && checkedType) ||
                (selectedRecipients?.selectedInternalRecipients?.length > 0 &&
                  checkedType) ||
                (selectedRecipients?.selectedExternalRecipients?.length > 0 &&
                  checkedType)
                  ? null
                  : "#8C8C8C"
              }
            />
          </Box>
        </StyledFlex>
      </StyledSpaceBetween>
    </StyledBorderDiv>
  );
};

export default DrawerInput;
