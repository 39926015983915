import React, { useState } from "react";
import { Badge, Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CustomInput,
  SearchBox,
  ChatSearchButton,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";
import searchIcon from "../../../assets/images/chats/gallery_active.svg";
import {
  StyledSideBarHeader,
  StyledSideBarInfoBox,
  StyledSidebarButton,
  createStyledIndicatorButton,
} from "../../../common/StyledComponents/SideBarComponents";
import addIcom from "../../../assets/images/chats/Add.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { handleSearchGroupByName } from "../../../appStore/actions/ArkchatGroup/action";
import ProfileDetailModal from "../../../common/ProfileDetailModal";
import InvitePopup from "../../../common/InvitePopup";
import group_icon from "../../../assets/images/chats/group_icon.svg";
import buyer_seller_grp_icon from "../../../assets/images/chats/buyer_seller_grp_icon.svg";
import moment from "moment";
const classes = {
  headingText: {
    color: "black",
    fontSize: "14px",
    fontWeight: "400",
    "@media (max-width: 63em)": {
      fontSize: "0.8rem",
    },
  },

  selectedItem: {
    // backgroundColor: "#183243",
    backgroundColor: "#E1E1E1",
    color: "#000",
  },
  nonselectedItem: {
    color: "#000",
  },
};

const SideBar = ({ handlecreategroup, handlegroupItemclick }) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const groupList = useSelector((state) => state?.arkchatgroup?.groupList);
  const cloneGroupList = useSelector(
    (state) => state?.arkchatgroup?.cloneGroupList
  );
  const dispatch = useDispatch();
  const SmallActionButton = createStyledIndicatorButton("#E3E1E1");
  const [isInviteOpen, setInviteOpen] = useState(false);
  const [isProfileViewOpen, setProfileViewModal] = useState(false);
  const [profileViewData, setProfileViewData] = useState("");
  const userData = useSelector((state) => state?.profile);

  const handleOpenMenu = () => {
    setInviteOpen(true);
  };

  const handleCloseMenu = () => {
    setInviteOpen(false);
  };

  const handleOnSearchGroup = (event) => {
    const { value } = event.target;

    if (value) {
      const FilterdGroup = cloneGroupList?.filter((item) =>
        item?.name?.toLowerCase().includes(value.toLowerCase())
      );

      //

      dispatch(handleSearchGroupByName(FilterdGroup));
    } else {
      dispatch(handleSearchGroupByName(cloneGroupList));
    }

    // handleSearchGroupByName
  };
  const handleGroupProfileBadgeClick = (
    data,
    isGroup,
    isBuyerSellerGroup = false
  ) => {
    const viewData = {
      companyName:
        data?.accountDetails?.subscriberFirmName ||
        data?.activeSubscriber?.subscriberFirmName,
      userName: isGroup
        ? null
        : data?.name || `${data?.firstName} ${data?.lastName}`,
      indusrtyName:
        data?.accountDetails?.subscriberAccountIndustry ||
        data?.activeSubscriber?.subscriberAccountIndustry,
      cityName: data?.accountDetails?.city || data?.city,
      countryName: data?.accountDetails?.country || data?.country,
      profileBrief: data?.accountDetails?.profileBrief || data?.profileBrief,
      profileImgUrl: data?.accountDetails?.profileImgUrl || data?.profileImgUrl,
      coverImgUrl: data?.accountDetails?.coverImgUrl || data?.coverImgUrl,
      isGroup: isGroup,
      isBuyerSellerGroup: isBuyerSellerGroup,
      website: data?.accountDetails?.website || data?.website,
      linkedin: data?.accountDetails?.linkedin || data?.linkedin,
    };
    setProfileViewData(viewData);
    setProfileViewModal(true);
  };
  return (
    <Stack bgcolor={"#F9F8F8"}>
      <ProfileDetailModal
        setProfileViewModal={setProfileViewModal}
        isProfileViewOpen={isProfileViewOpen}
        profileViewData={profileViewData}
        setProfileViewData={setProfileViewData}
      />
      <StyledSideBarHeader
        sx={{ bgcolor: "#efefef", alignItems: "center" }}
        position={"relative"}
      >
        <Box display="flex" alignItems="center" gap={"0.5rem"}>
          <Typography sx={classes.headingText} variant="h6">
            {appContent?.groupsNdContact}
          </Typography>
        </Box>
      </StyledSideBarHeader>

      <StyledSideBarInfoBox>
        <Box
          display="flex"
          gap={"8px"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          {/* <Box width={"73.2%"} mr={"6%"}> */}
          <Box width={"100%"}>
            <SearchBox>
              <CustomInput
                placeholder="Search"
                onChange={handleOnSearchGroup}
              />
              <ChatSearchButton size="small" variant="contained">
                <SmallIcon src={searchIcon} />
              </ChatSearchButton>
            </SearchBox>
          </Box>
          <Box>
            <StyledSidebarButton
              sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}
              onClick={handlecreategroup}
              size="small"
            >
              <img src={addIcom} alt="group" />
              {appContent?.group}
            </StyledSidebarButton>
          </Box>
        </Box>

        <Box
          bgcolor={"#F9F8F8"}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={"0.4rem"}
          rowGap={"0.4rem"}
          overflow={"scroll"}
          height={"calc(100vh - 16rem)"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "1px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#F9F8F8",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "grey",
            },
            "@media (max-width: 1194px)": {
              height: "calc(100vh - 18rem)",
            },
          }}
        >
          {groupList?.map((item, i) => {
            return (
              <div
                key={item?.id + "groupList" + i + "unique"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  width={"75%"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.1rem",
                    cursor: "pointer",
                  }}
                >
                  <Box width={"15%"}>
                    <SmallActionButton
                      size="small"
                      variant="contained"
                      sx={{
                        padding:
                          item?.accountDetails?.profileImgUrl && !item?.isGroup
                            ? "0px"
                            : "6px",
                        overflow: "hidden",
                      }}
                      onClick={() =>
                        item?.isGroup
                          ? handleGroupProfileBadgeClick(
                              item,
                              item?.isGroup,
                              item?.isBuyerSellerGroup
                            )
                          : handleGroupProfileBadgeClick(item, item?.isGroup)
                      }
                    >
                      {item?.isGroup ? (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={
                            item?.isBuyerSellerGroup
                              ? buyer_seller_grp_icon
                              : group_icon
                          }
                          alt="group"
                        />
                      ) : (
                        <>
                          {item?.accountDetails?.profileImgUrl ? (
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={item?.accountDetails?.profileImgUrl}
                              alt=""
                            />
                          ) : (
                            <Typography fontSize={"0.75rem"} color={"#183243"}>
                              {item?.name && item?.name[0]}
                            </Typography>
                          )}
                        </>
                      )}
                    </SmallActionButton>
                  </Box>

                  <Box
                    width={"84%"}
                    sx={
                      // eslint-disable-next-line eqeqeq
                      selectedGroup?.pnId == item?.pnId &&
                      selectedGroup?.name === item?.name
                        ? classes?.selectedItem
                        : classes?.nonselectedItem
                    }
                    padding={"0.2rem 0.4rem"}
                    borderRadius="0.1rem"
                    // mr={"6.2%"}
                    cursor="pointer"
                    onClick={() => handlegroupItemclick(item)}
                    position={"relative"}
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      <Typography
                        maxWidth={
                          !item?.isGroup &&
                          item?.id === userData?.profileData?.data?.id
                            ? "60%"
                            : "80%"
                        }
                        fontSize="14px"
                        fontWeight="400"
                        color={
                          selectedGroup?.id === item?.id ? "black" : "black"
                        }
                        variant="h6"
                        title={item?.name}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.name}
                      </Typography>
                      {!item?.isGroup &&
                        item?.id === userData?.profileData?.data?.id && (
                          <Typography ml={"0.1rem"} variant="caption">
                            ({appContent?.you})
                          </Typography>
                        )}
                    </Box>
                    {item?.messageCount !== 0 &&
                      item?.pnId !== selectedGroup?.pnId && (
                        <Box
                          position={"absolute"}
                          right={"10%"}
                          top={"48%"}
                          sx={{ transform: "translateY(-48%)" }}
                        >
                          <Badge
                            color="success"
                            badgeContent={item?.messageCount}
                            max={20}
                            sx={{
                              "& .MuiBadge-badge": {
                                backgroundColor: "#183243",
                                fontSize: "0.7rem",
                              },
                            }}
                          ></Badge>
                        </Box>
                      )}
                  </Box>
                </Box>

                {item?.timetoken !== 0 && item?.timetoken && (
                  <Box padding={"0rem 0.1rem 0rem 0rem"} width={"25%"} textAlign={"right"}>
                    <Typography
                      variant="h6"
                      fontSize={"0.74rem"}
                      fontWeight={500}
                      color={"#183243"}
                    >
                      {(() => {
                        const timetoken = item?.timetoken;
                        const date = moment(Math.floor(timetoken / 10000));
                        const isToday = date.isSame(moment(), "day");
                        return isToday
                          ? date.format(
                              userData?.profileData?.data?.userTimeFormat
                            )
                          : date.format(
                              userData?.profileData?.data?.userDateFormat
                            );
                      })()}
                    </Typography>
                  </Box>
                )}
              </div>
            );
          })}
        </Box>
      </StyledSideBarInfoBox>
      <Box
        position={"relative"}
        bgcolor={"#F9F8F8"}
        width={"100%"}
        alignSelf={"flex-end"}
        textAlign={"center"}
        mt={"3rem"}
        sx={{
          "@media (max-width: 1194px)": {
            mt: "0rem",
          },
        }}
      >
        <Box
          display={"flex"}
          padding={"0.2rem 0.4rem"}
          bgcolor={"#efefef"}
          alignItems={"center"}
        >
          <SmallActionButton
            size="small"
            variant="contained"
            sx={{ mr: "0.2rem" }}
            onClick={() =>
              handleGroupProfileBadgeClick(userData?.profileData?.data)
            }
          >
            <Typography fontSize={"0.75rem"} color={"#183243"}>
              {userData?.profileData?.data?.firstName &&
                userData?.profileData?.data?.firstName[0]}
            </Typography>
          </SmallActionButton>
          <Typography
            textAlign={"start"}
            width={"80%"}
            fontSize="14px"
            color={"#000000"}
            variant="h6"
            title={`${userData?.profileData?.data?.firstName} ${userData?.profileData?.data?.lastName}`}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {userData?.profileData?.data?.firstName}{" "}
            {userData?.profileData?.data?.lastName}
          </Typography>

          <StyledSidebarButton
            sx={{ fontSize: "0.75rem" }}
            onClick={handleOpenMenu}
          >
            <img src={addIcom} alt="group" />{" "}
            <Typography ml={"0.2rem"} fontSize={"0.75rem"}>
              {appContent?.invite}
            </Typography>
          </StyledSidebarButton>
          {isInviteOpen && <InvitePopup closePopUp={handleCloseMenu} />}
        </Box>
      </Box>
    </Stack>
  );
};

export default SideBar;
