import { Tooltip, Box } from "@mui/material";

// Reusable Tooltip Component
const LableToolTip = ({ title, children }) => (
  <Tooltip title={title} arrow>
    <Box component="span">{children}</Box>
  </Tooltip>
);

export default LableToolTip;
