import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import useAppSelectors from "../../../../customHooks/useAppSelectors";
import { AccountLevelArkchatPermission } from "../../../../utils/constant";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#E1E1E1",
    color: "black",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },

  internalExternalButton: {
    background: "white",
    border: "1px solid #183243",
    color: "#183243",
    borderRadius: "0px",
    "&:hover": {
      background: "white",
    },
  },

  activeButton: {
    "&:hover": {
      background: "#183243",
    },
    background: "#183243",
    borderRadius: "0px",
    color: "white",
  },

  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  radiogp: {
    display: "block",
  },
  groupMember: {
    backgroundColor: "#F3F3F3",
    padding: "20px 10px",
  },

  // add group member
  addgmember: {
    transform: "translate(0, 70%)",
    textAlign: "center",
  },
  // add group member
};

const Resource = ({
  setResourceUtilisationOpen,
  selectedTaskGroup,
  selectedTaskmember,
  taskTabValue,
}) => {
  const { currentUserData } = useAppSelectors();
  const taskAnalyticsData = useSelector(
    (state) => state?.arkchatgroup?.taskAnalyticsData
  );
  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={classes.iconDiv}>
                <CloseIcon
                  sx={classes.iconn}
                  onClick={() => setResourceUtilisationOpen(false)}
                />
              </Typography>
              <Typography variant="subtitle2">
                {currentUserData?.activePermission ===
                AccountLevelArkchatPermission?.ADMIN ? (
                  <>
                    {selectedTaskGroup?.length > 0
                      ? `${selectedTaskGroup[0]?.name}`
                      : selectedTaskmember?.length
                      ? `${selectedTaskmember[0]?.userInfo?.firstName} ${selectedTaskmember[0]?.userInfo?.lastName}`
                      : taskTabValue === 1
                      ? `${currentUserData?.firstName} ${currentUserData?.lastName}`
                      : "Group"}
                  </>
                ) : (
                  <>{`${currentUserData?.firstName} ${currentUserData?.lastName}`}</>
                )}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            width={"95%"}
            margin={"auto"}
            mt={"0.5rem"}
            overflow={"scroll"}
            overflowY={"hidden"}
            height={"calc(100vh - 7rem)"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "2px",
                padding: "0px 2px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#F9F8F8",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "grey",
              },
            }}
          >
            {taskAnalyticsData?.groupData?.map((groupAnalyticsData) => (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mb={"0.5rem"}
              >
                <Box width={"75%"}>
                  <Typography fontSize={"0.85rem"}>
                    {groupAnalyticsData?.name}
                  </Typography>
                </Box>

                <Box width={"20%"} display={"flex"} gap="0.2rem">
                  <Typography fontSize={"0.85rem"} fontWeight={"600"}>
                    {Math.floor(groupAnalyticsData?.analysis_time?.timeValue)}
                  </Typography>
                  <Typography fontSize={"0.85rem"} fontWeight={"600"}>
                    {groupAnalyticsData?.analysis_time?.timeType}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Stack>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Resource;
