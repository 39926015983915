import styled from "@emotion/styled";

export const StyledDiv = styled.div`
    
`;

export const StyledBorderDiv = styled.div`
    border-top: 1px solid black;
`

export const StyledSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 8px 0 8px 0;
`

export const StyledFlex = styled.div`
    display: flex;
`
export const StyledFlexEnd = styled.div`
    display: 'flex';
    justifyContent: 'flex-end'
`;

export const StyledFlexCenter = styled.div`
    display: flex;
    justify-content: center;
    width: 33%;
    text-align: center;
    margin: auto;
`;


export const StyledFlexStart = styled.div`
    display: 'flex';
    justifyContent: 'flex-start'
`;

export const StyledChatContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 122px);
`;