import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, TextareaAutosize, Box, Paper } from "@mui/material";
import { StyledBorderDiv, StyledFlex, StyledSpaceBetween } from "../../Styled";
// import Attherate from "../../assets/images/at-theRate.svg";
import ChatAdd from "../../assets/images/chat_add.svg";
// import ChatSend from "../../assets/images/chat_send.svg";
import filesvg from "../../assets/images/chats/file_svg.svg";
import googlesvg from "../../assets/images/chats/google_svg.svg";
import youtubesvg from "../../assets/images/chats/youtube_svg.svg";
import exploresvg from "../../assets/images/chats/explore_svg.svg";
import { generateUniqueId } from "../../utils/copytoClipboard";
import Toastbar from "../../common/Toastbar/Toastbar";
import SelectedAttachment from "./MessageComponent/SelectedAttachment.jsx";
import { handleChangeTopicOpen } from "../../appStore/actions/AppState/action.js";
import Attherate from "../../common/SvgImageView/Attherate.jsx";
import ChatSend from "../../common/SvgImageView/Send.jsx";
import replaym from "../../assets/images/chats/reply.svg";
import { ActionButton } from "../../common/chatComponents/ScreenSearchSection.jsx";
import { showToastMsg } from "../../appStore/actions/global/action.js";
import LableToolTip from "../../common/LableToolTip.jsx";
import { useTranslation } from "react-i18next";

const ChatInputControl = ({
  handleclickAttherate,
  newMessages,
  handleMessageSend,
  handleOnChangeNewMessage,
  handleClickYouTube,
  setFileAttachment,
  handleClickGoogle,
  fileAttachment,
  attachment,
  setisTopicOpen,
  setAttachment,
  replyParentMsg,
  setReplyParentMsg,
  isPrivateChatOn,
  isTaskUpdateOpen,
  setMoveTopicTriggered,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [isAttachmentMenuOpen, setAttachmentMenuOpen] = useState(false);
  const [isFileSectionDisabled, setFileSectionDisabled] = useState(false);
  const [allAttachment, setAllAttachment] = useState([]);
  const fileRef = useRef(null);
  const menuRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const selectedTopicToSaveMsg = useSelector(
    (state) => state?.appStateData?.selectedTopicToSaveMsg
  );
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const userData = useSelector((state) => state?.profile);
  const isThreadScreen = useSelector(
    (state) => state?.localAppReducer?.isThreadScreen
  );
  //
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleCloseAttachmentMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const combinedLength = fileAttachment?.length + attachment?.length;
    const allFilesData = [...fileAttachment, ...attachment];
    setAllAttachment(allFilesData);

    // Setting the disabled state of the checkbox not more than 5 overall attachment
    setFileSectionDisabled(combinedLength === 5);
  }, [fileAttachment, attachment]);
  //
  const handleFileChange = (e) => {
    setAttachmentMenuOpen(false);
    const files = e.target.files; // Get the selected files
    const maxFiles = 5;
    if (files?.length + attachment.length > maxFiles) {
      e.preventDefault();
      dispatch(showToastMsg(`You can only select up to 5 attachment.`));
      return;
    }

    // Convert the FileList to an array

    const fileListWithIds = Array.from(files).map((file) => ({
      id: generateUniqueId(file?.name),
      file: file,
    }));

    // Update the state with the array of files
    setFileAttachment(fileListWithIds);
  };
  const handleAttachmentMenu = () => {
    if (selectedGroup) {
      if (setAttachmentMenuOpen) {
        setAttachmentMenuOpen(!isAttachmentMenuOpen);
      }
    }
  };
  const handleCloseAttachmentMenu = () => {
    setAttachmentMenuOpen(false);
  };

  const handleRemoveAttachment = (attachmentId) => {
    // Remove the file with the specified ID from the state
    const updatedattachment = attachment?.filter(
      (file) => file.id !== attachmentId
    );
    setAttachment(updatedattachment);
    setReplyParentMsg("");
    const updatedFileattachment = fileAttachment?.filter(
      (file) => file.id !== attachmentId
    );
    setFileAttachment(updatedFileattachment);

    setAllAttachment([...updatedFileattachment, ...updatedattachment]);
  };

  //
  const triggerHandelSend = () => {
    if (selectedGroup) {
      if (newMessages) {
        handleMessageSend();
      } else {
        setSnackbarMessage("Type a message");
        setOpenSnackbar(true);
      }
    }
  };
  return (
    <>
      <Toastbar
        setOpenSnackbar={setOpenSnackbar}
        openSnackbar={openSnackbar}
        snackbarMessage={snackbarMessage}
        severity={"warning"}
      />
      {replyParentMsg && (
        <Box
          sx={{
            background: "#E3E1E1",
            padding: "8px 10px",
            cursor: "pointer",
            wordWrap: "break-word",
          }}
          display={"flex"}
          alignItems={"center"}
          gap={"0.4rem"}
        >
          <Box>
            <img src={replaym} alt="reply" />
          </Box>
          <Typography
            width={"85%"}
            sx={{ wordWrap: "break-word" }}
            fontSize={"0.87rem"}
          >
            {replyParentMsg?.to?.findIndex(
              (idData) => idData?.id === userData?.profileData?.data?.id
            ) !== -1 &&
              replyParentMsg?.translations[
                replyParentMsg?.to?.find(
                  (data) => data?.id === userData?.profileData?.data?.id
                )?.languageCode
              ]?.slice(0, 200) + "..."}
          </Typography>

          <Box position={"absolute"} right={"0.5rem"}>
            <ActionButton
              onClick={() => setReplyParentMsg("")}
              sx={{ height: "25px", width: "25px" }}
            >
              X
            </ActionButton>
          </Box>
        </Box>
      )}

      <StyledBorderDiv
        style={{
          position: "relative",
          zIndex: isTaskUpdateOpen ? 1199 : 1201,
          backgroundColor: "#ffff",
        }}
      >
        {/* isAllowedToChat?.membership */}
        {((selectedGroup?.isGroup && selectedGroup?.membership) ||
          !selectedGroup?.isGroup) && (
          <>
            <TextareaAutosize
              style={{
                width: "100%",
                resize: "vertical",
                border: "none",
                outline: "none",
                lineHeight: "1.25rem",
                padding: "5px 8px",
                fontFamily: "Poppins",
                fontSize: "0.87rem",
                maxHeight: "25vh",
                minHeight: "1.1rem",
                overflow: "auto",
                // marginBottom: "3rem",
              }}
              minRows={"1"}
              placeholder="Type message ..."
              disabled={!selectedGroup}
              value={newMessages}
              onChange={handleOnChangeNewMessage}
            />

            {allAttachment?.length > 0 && (
              <Box
                width={"100%"}
                position={"relative"}
                height={"3rem"}
                overflow={"scroll"}
                sx={{
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    width: "1px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "transparent",
                  },
                }}
              >
                <SelectedAttachment
                  data={allAttachment}
                  handleRemoveAttachment={handleRemoveAttachment}
                  replyParentMsg={replyParentMsg}
                />
              </Box>
            )}

            <StyledSpaceBetween
              style={{
                padding: "0rem .5rem",
                // position: "absolute",
                // bottom: "0rem",
                width: "100%",
              }}
            >
              <input
                type="file"
                multiple
                name="file"
                ref={fileRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <StyledFlex>
                <Box position={"relative"}>
                  {isAttachmentMenuOpen && (
                    <Box
                      ref={menuRef}
                      position="absolute"
                      top={"-9.2rem"}
                      right={"-10.5rem"}
                      width={"12.3rem"}
                    >
                      <Paper
                        sx={{
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                          padding: "0.7rem 0rem",
                        }}
                      >
                        <Box>
                          <Box
                            p={"0.4rem 1rem"}
                            sx={{
                              cursor: "pointer",
                              pointerEvents: isFileSectionDisabled
                                ? "none"
                                : "",
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              onClick={() => fileRef.current.click()}
                            >
                              <Box>
                                <Typography>Files</Typography>
                              </Box>
                              <Box>
                                <img src={filesvg} alt="file" />
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            mt={"0.7rem"}
                            p={"0.4rem 1rem"}
                            sx={{ cursor: "pointer" }}
                            onClick={handleClickGoogle}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box>
                                <Typography>Google</Typography>
                              </Box>
                              <Box>
                                <img src={googlesvg} alt="file" />
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            mt={"0.7rem"}
                            p={"0.4rem 1rem"}
                            sx={{ cursor: "pointer" }}
                            onClick={handleClickYouTube}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box>
                                <Typography>Youtube</Typography>
                              </Box>
                              <Box>
                                <img src={youtubesvg} alt="file" />
                              </Box>
                            </Box>
                          </Box>

                          {/* <Box
                            mt={"0.7rem"}
                            p={"0.4rem 1rem"}
                            sx={{ cursor: "pointer" }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box>
                                <Typography>Arkchat Explore</Typography>
                              </Box>
                              <Box>
                                <img src={exploresvg} alt="file" />
                              </Box>
                            </Box>
                          </Box> */}
                        </Box>
                      </Paper>
                    </Box>
                  )}
                  <LableToolTip title={appContent?.chatAttachmentBtnLable}>
                    <img
                      className="chat-input-icon"
                      src={ChatAdd}
                      alt="attachment"
                      onClick={handleAttachmentMenu}
                    />
                  </LableToolTip>
                </Box>
                {/* <img
              style={{ display: "none" }}
              className="chat-input-icon"
              src={ChatVideo}
              alt="video"
            /> */}
                {/* <img className="chat-input-icon" src={ChatMic} alt="mic" /> */}
              </StyledFlex>
              <StyledFlex>
                {selectedGroup?.isGroup &&
                  isPrivateChatOn &&
                  !isThreadScreen && (
                    <>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                        mr={"1rem"}
                        maxWidth={"100%"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap={"0.5rem"}
                          justifyContent={"center"}
                        >
                          <Box>
                            <Typography
                              variant="h5"
                              fontSize={"0.7rem"}
                              color={"#00000"}
                              fontWeight={600}
                              mr={"0.5rem"}
                              title={`Your message will be saved in Topic : ${
                                selectedTopicToSaveMsg
                                  ? selectedTopicToSaveMsg.name
                                  : "General"
                              }`}
                            >
                              {`Your message will be saved in Topic : ${
                                selectedTopicToSaveMsg
                                  ? selectedTopicToSaveMsg.name.slice(0, 20) +
                                    (selectedTopicToSaveMsg.name?.length > 20
                                      ? " ..."
                                      : "")
                                  : "General"
                              }`}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          onClick={() => {
                            setisTopicOpen(true);
                            setMoveTopicTriggered(false);
                            dispatch(handleChangeTopicOpen(true));
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          <Typography
                            variant="h5"
                            color={"#DB0011"}
                            fontSize={"0.7rem"}
                            fontWeight={600}
                          >
                            Change
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                {selectedGroup?.isGroup && isPrivateChatOn && (
                  <LableToolTip title={appContent?.selectMsgRecipientBtnLable}>
                    <Box
                      className="chat-input-left-icon"
                      onClick={newMessages ? handleclickAttherate : undefined}
                    >
                      <Attherate color={newMessages ? null : "#8C8C8C"} />
                    </Box>
                  </LableToolTip>
                )}
                <LableToolTip title={appContent?.chatSendBtnLabel}>
                  <Box
                    className="chat-input-left-icon"
                    onClick={triggerHandelSend}
                  >
                    <ChatSend color={newMessages ? null : "#8C8C8C"} />
                  </Box>
                </LableToolTip>
              </StyledFlex>
            </StyledSpaceBetween>
          </>
        )}
        {selectedGroup &&
          !selectedGroup?.membership &&
          selectedGroup?.isGroup && (
            <Box bgcolor={"#F3F3F3"} padding={"2.5rem"} textAlign={"center"}>
              <Typography>Renew Subscription</Typography>
            </Box>
          )}
      </StyledBorderDiv>
    </>
  );
};
export default ChatInputControl;
